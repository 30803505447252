/* styles/OverdueClients.css */
.overdue-container {
    padding: 20px;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .overdue-header {
    margin-bottom: 24px;
  }
  
  .overdue-header h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .search-container {
    margin-bottom: 16px;
  }
  
  .search-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
  }
  
  .filter-buttons {
    display: flex;
    gap: 12px;
    margin-bottom: 20px;
  }
  
  .filter-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    background-color: #f3f4f6;
    transition: all 0.2s;
  }
  
  .filter-btn:hover {
    background-color: #e5e7eb;
  }
  
  .filter-btn.active {
    background-color: #3b82f6;
    color: white;
  }
  
  .filter-btn.active.overdue {
    background-color: #ef4444;
  }
  
  .filter-btn.active.upcoming {
    background-color: #f59e0b;
  }
  
  .table-container {
    overflow-x: auto;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th {
    background-color: #f9fafb;
    padding: 12px 16px;
    text-align: left;
    font-weight: 600;
    color: #374151;
    border-bottom: 1px solid #e5e7eb;
  }
  
  td {
    padding: 12px 16px;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .client-row {
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .client-row:hover {
    background-color: #f9fafb;
  }
  
  .client-row.overdue {
    background-color: #fee2e2;
  }
  
  .client-row.overdue:hover {
    background-color: #fecaca;
  }
  
  .client-row.upcoming {
    background-color: #fef3c7;
  }
  
  .client-row.upcoming:hover {
    background-color: #fde68a;
  }
  
  .status-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
  }
  
  .status-badge.overdue {
    background-color: #fee2e2;
    color: #b91c1c;
  }
  
  .status-badge.upcoming {
    background-color: #fef3c7;
    color: #92400e;
  }
  
  .status-badge.normal {
    background-color: #e5e7eb;
    color: #374151;
  }
  
  .overdue-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    font-size: 16px;
    color: #6b7280;
  }
  /* styles/OverdueClients.css */
.filter-checkboxes {
    display: flex;
    gap: 20px;
    margin: 16px 0;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  
  .checkbox-label input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }
  
  .mark-problematic-btn {
    padding: 6px 12px;
    background-color: #dc2626;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s;
  }
  
  .mark-problematic-btn:hover {
    background-color: #b91c1c;
  }
  
  /* Остальные стили остаются без изменений */
  /* Добавьте эти стили к существующим в OverdueClients.css */

.toggle-problematic-btn {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    transition: all 0.2s;
    white-space: nowrap;
  }
  
  .toggle-problematic-btn.add {
    background-color: #dc2626;
    color: white;
  }
  
  .toggle-problematic-btn.add:hover {
    background-color: #b91c1c;
  }
  
  .toggle-problematic-btn.remove {
    background-color: #4b5563;
    color: white;
  }
  
  .toggle-problematic-btn.remove:hover {
    background-color: #374151;
  }
  
  .filter-checkboxes {
    display: flex;
    gap: 20px;
    margin: 16px 0;
    align-items: center;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    user-select: none;
  }
  
  .checkbox-label input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }