.analytics-container {
  max-width: 800px;  /* Ограничиваем максимальную ширину */
  margin: 0 auto;    /* Центрируем контейнер */
  padding: 25px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}


.analytics-title {
  margin-bottom: 25px;
  color: #2c3e50;
  padding-bottom: 15px;
  border-bottom: 2px solid #eee;
  font-size: 24px;
  font-weight: 600;
}

.analytics-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.analytics-data-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.analytics-data-cell {
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.analytics-data-row:last-child .analytics-data-cell {
  border-bottom: none;
}

.analytics-label-cell {
  width: 40%;
  font-weight: 500;
  color: #2c3e50;
  background-color: #f8f9fa;
}

.analytics-input {
  width: 100%;
  padding: 10px 12px;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.analytics-input:hover {
  border-color: #90caf9;
}

.analytics-input:focus {
  border-color: #2196f3;
  outline: none;
  box-shadow: 0 0 0 3px rgba(33,150,243,0.1);
}

.analytics-section-header {
  background-color: #2196f3 !important;
  color: white;
  font-weight: bold;
  padding: 12px 15px;
  font-size: 16px;
}

.analytics-total-row .analytics-data-cell {
  background-color: #f3f4f6;
  font-weight: 600;
  color: #2c3e50;
}

.analytics-profit-row .analytics-data-cell {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.analytics-save-btn {
  padding: 14px 28px;
  background-color: #2196f3 !important; /* Принудительно задаем цвет */
  color: #fff !important;  /* Принудительно задаем цвет текста */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(33,150,243,0.2);
  margin-top: 20px;
  display: block;  /* Делаем блочным элементом */
  width: fit-content; /* Ширина по содержимому */
  opacity: 1 !important; /* Принудительно убираем прозрачность */
}

.analytics-save-btn:hover {
  background-color: #1976d2 !important;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(33,150,243,0.3);
}

.analytics-save-btn:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(33,150,243,0.2);
}

/* Изменим размеры для мобильных устройств */
@media (max-width: 768px) {
  .analytics-container {
    padding: 15px;
    margin: 0 10px;  /* Добавляем отступы по бокам на мобильных */
  }
  
  .analytics-data-cell {
    padding: 12px;
  }
  
  .analytics-label-cell {
    width: 50%;
  }

  .analytics-save-btn {
    width: 100%;
    padding: 12px;
  }
}