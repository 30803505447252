/* frontend/src/styles/Tabs.css */

.tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .tab-titles {
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .tab-titles button {
    padding: 10px 20px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    transition: color 0.2s ease, border-bottom 0.2s ease;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .tab-titles button:hover {
    color: #007bff;
  }
  
  .tab-titles button.active {
    border-bottom: 2px solid #007bff;
    color: #007bff;
  }
  
  .tab-content {
    max-width: 800px;
    width: 100%;
    padding: 20px;
    background-color: #ffffff00;
    border: 1px solid #dddddd00;
    border-radius: 4px;
    margin-top: 10px;
  }
  