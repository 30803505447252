.create-client {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .create-client h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .create-client-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-section {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .form-section h2 {
    font-size: 18px;
    margin-bottom: 20px;
    color: #444;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .form-group label {
    font-weight: 500;
    color: #666;
  }
  
  .form-group input,
  .form-group select {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
  }
  
  .form-group input:focus,
  .form-group select:focus {
    border-color: #2196f3;
    outline: none;
    box-shadow: 0 0 0 2px rgba(33,150,243,0.1);
  }
  
  .checkbox-group label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  
  .checkbox-group input[type="checkbox"] {
    width: auto;
  }
  
  .total-expenses {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .submit-button {
    padding: 12px 24px;
    background-color: #2196f3;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .submit-button:hover {
    background-color: #1976d2;
  }
  
  /* Стили для обязательных полей */
  input:required, select:required {
    border-left: 3px solid #2196f3;
  }
  
  /* Стили для состояний наведения и фокуса */
  .form-group input:hover,
  .form-group select:hover {
    border-color: #90caf9;
  }
  
  /* Адаптивность для маленьких экранов */
  @media (max-width: 768px) {
    .create-client {
      padding: 10px;
    }
    
    .form-grid {
      grid-template-columns: 1fr;
    }
    
    .form-section {
      padding: 15px;
    }
  }