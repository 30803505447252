:root {
  --background-primary: #f5f5f7;
  --background-secondary: #ffffff;
  --text-primary: #1d1d1f;
  --text-secondary: #86868b;
  --accent-color: #0071e3;
  --border-color: #d2d2d7;
  --hover-color: #f2f2f2;
  --sidebar-width: 150px;
}

.dashboard {
  display: flex;
  min-height: 100vh;
  background-color: var(--background-primary);
  color: var(--text-primary);
}

.sidebar {
  width: var(--sidebar-width);
  background-color: var(--background-secondary);
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100vh;
  border-right: 1px solid var(--border-color);
}

.logo-container {
  text-align: center;
  margin-bottom: 40px;
  padding: 0 16px;
}

.logo {
  max-width: 120px;
  height: auto;
}
.menu-container {
  flex-grow: 1;
  margin-left: -8px;
  padding-right: 8px;
}

.menu-btn {
  width: calc(100% + 8px);
  padding: 12px 16px;
  margin-bottom: 4px;
  border: none;
  border-radius: 8px;
  background-color: transparent;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
}

.bottom-menu {
  margin-top: auto;
  border-top: 1px solid var(--border-color);
  margin-left: -8px;
  padding-top: 16px;
  padding-bottom: 32px;
}
.menu-btn i {
  width: 18px;
  color: var(--text-secondary);
  font-size: 16px;
}

.menu-btn:hover {
  background-color: var(--hover-color);
}

.menu-btn.active {
  background-color: var(--hover-color);
  color: var(--accent-color);
}

.menu-btn.active i {
  color: var(--accent-color);
}



.main-content {
  flex-grow: 1;
  margin-left: var(--sidebar-width);
  padding: 32px 40px;
}

.main-content h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  margin-left: 24px;
  color: var(--text-primary);
}

.filters-section {
  margin-left: 24px;
  margin-right: 24px;
  background-color: var(--background-secondary);
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 24px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.table-container {
  margin-left: 24px;
  margin-right: 24px;
}
.search-filter {
  margin-bottom: 24px;
}

.search-input {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.2s ease;
  background-color: var(--background-primary);
}

.search-input:focus {
  outline: none;
  border-color: var(--accent-color);
}

.date-filters {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
}

.date-range {
  flex: 1;
}

.date-range h4 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: var(--text-secondary);
}

.date-range input {
  padding: 10px 12px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 14px;
  background-color: var(--background-primary);
  width: calc(50% - 12px);
}

.date-range input:focus {
  outline: none;
  border-color: var(--accent-color);
}

.date-range span {
  margin: 0 8px;
  color: var(--text-secondary);
}

.work-type-filter h4 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
  color: var(--text-secondary);
}

.work-types-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.work-type-checkbox {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;
  background-color: var(--background-primary);
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
  user-select: none;
}

.work-type-checkbox:hover {
  background-color: var(--hover-color);
}

.work-type-checkbox input[type="checkbox"] {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  position: relative;
  cursor: pointer;
}

/* Стили для таблицы */


.client-list {
  width: 100%;
  border-collapse: collapse;
}

.client-list th {
  background-color: var(--background-secondary);
  padding: 16px;
  text-align: left;
  font-weight: 500;
  color: var(--text-secondary);
  border-bottom: 1px solid var(--border-color);
  font-size: 14px;
}

.client-list td {
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
  font-size: 14px;
  color: var(--text-primary);
}

.client-list tr:hover {
  background-color: var(--hover-color);
  cursor: pointer;
}

/* Медиа-запрос для адаптивности */
@media (max-width: 1200px) {
  .date-filters {
    flex-direction: column;
  }
  
  .date-range input {
    width: calc(50% - 12px);
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 64px;
    padding: 20px 8px;
  }
  
  .menu-btn span {
    display: none;
  }
  
  .main-content {
    margin-left: 64px;
    padding: 20px;
  }
  
  .logo-container {
    margin-bottom: 24px;
  }
  
  .logo {
    max-width: 32px;
  }
}