:root {
  --background-primary: #f5f5f7;
  --background-secondary: #ffffff;
  --text-primary: #1d1d1f;
  --text-secondary: #86868b;
  --accent-color: #0071e3;
  --border-color: #d2d2d7;
  --hover-color: #f2f2f2;
  --success-color: #34c759;
  --warning-color: #ff9f0a;
}

.client-card {
  background-color: var(--background-secondary);
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.card-header h2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.edit-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background-color: var(--background-primary);
  color: var(--accent-color);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.edit-button:hover {
  background-color: var(--hover-color);
}

.edit-button i {
  font-size: 16px;
}

.sections-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.info-section {
  background-color: var(--background-primary);
  border-radius: 12px;
  padding: 20px;
}

.info-section h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0 0 16px 0;
}

.fields-grid {
  display: grid;
  gap: 12px;
}

.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.field:hover {
  background-color: var(--background-secondary);
}

.field label {
  font-size: 14px;
  color: var(--text-secondary);
  margin-right: 16px;
}

.field .value {
  font-size: 14px;
  color: var(--text-primary);
  font-weight: 500;
}

.field.highlighted {
  background-color: var(--background-secondary);
}

.field.highlighted .value {
  color: var(--accent-color);
  font-weight: 600;
}

.loading-state {
  text-align: center;
  padding: 40px;
  color: var(--text-secondary);
  font-size: 16px;
}

/* Стили для формы редактирования */
.edit-form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  margin-top: 24px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 14px;
  color: var(--text-secondary);
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group input[type="date"] {
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.2s ease;
}

.form-group input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.form-group input:focus {
  outline: none;
  border-color: var(--accent-color);
}

.button-group {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}

.button-group button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.save-button {
  background-color: var(--accent-color);
  color: white;
}

.save-button:hover {
  opacity: 0.9;
}

.cancel-button {
  background-color: var(--background-primary);
  color: var(--text-primary);
}

.cancel-button:hover {
  background-color: var(--hover-color);
}

@media (max-width: 768px) {
  .sections-grid {
    grid-template-columns: 1fr;
  }
  
  .edit-form {
    grid-template-columns: 1fr;
  }
  
  .card-header {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
  
  .edit-button {
    width: 100%;
    justify-content: center;
  }
}