/* frontend/src/styles/ClientDetails.css */

.client-details {
  padding: 20px;
  background-color: #f5f5f5;
}

.client-details h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.tabs {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
}

.tabs button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease;
  font-size: 16px;
}

.tabs button.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
}
/* В src/styles/ClientDetails.css */

.info-section {
  height: calc(100vh - 200px);
  padding: 20px;
  background: #f5f5f5;
}

.notes-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
  overflow: hidden;
}

.notes-header {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  background: #ffffff;
  border-bottom: 1px solid #f0f0f0;
}

.notes-header .button-group {
  display: flex;
  gap: 12px;
}

.notes-header .save-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  box-shadow: 0 2px 4px rgba(76, 175, 80, 0.2);
}

.notes-header .save-button:hover {
  background-color: #43a047;
  box-shadow: 0 4px 8px rgba(76, 175, 80, 0.3);
  transform: translateY(-1px);
}

.notes-header .save-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(76, 175, 80, 0.2);
}



.notes-textarea {
  flex: 1;
  width: 100%;
  padding: 24px;
  border: none;
  resize: none;
  font-size: 16px;
  line-height: 1.6;
  color: #2c3e50;
  background: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.notes-textarea::placeholder {
  color: #a0aec0;
}

.notes-textarea:focus {
  outline: none;
}

/* Стилизация скроллбара */
.notes-textarea::-webkit-scrollbar {
  width: 12px;
}

.notes-textarea::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-left: 1px solid #f0f0f0;
}

.notes-textarea::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 6px;
  border: 3px solid #f5f5f5;
}

.notes-textarea::-webkit-scrollbar-thumb:hover {
  background: #d0d0d0;
}
.tab-content {
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.client-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.export-button {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: block;
  margin: 10px 0;}

/* Стили для карточки клиента */
.client-card {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.client-card h2 {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.client-card .client-info {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px 20px;
}

.client-card .client-info p {
  margin: 0;
  padding: 5px 0;
}

.client-card .client-info label {
  font-weight: bold;
  color: #333;
}

.client-card .edit-button {
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  text-align: center;
  display: block;
  margin: 10px 0;
}

.client-card .edit-button:hover {
  color: #0056b3;
}

.client-card input[type="text"],
.client-card input[type="number"],
.client-card input[type="date"],
.client-card select,
.client-card input[type="checkbox"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.save-button,
.cancel-button {
  margin-top: 15px;
  padding: 10px 15px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.save-button {
  background-color: #28a745;
  color: white;
}

.cancel-button {
  background-color: #ffc107;
  color: black;
}

.save-button:hover {
  background-color: #218838;
}

.cancel-button:hover {
  background-color: #e0a800;
}
