/* src/styles/ChangeLog.css */
.change-log {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .change-log-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .change-log-header h3 {
    margin: 0;
    font-size: 1.5rem;
    color: #2c3e50;
  }
  
  .filter-select {
    padding: 8px 12px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 14px;
    color: #4a5568;
    background-color: white;
    cursor: pointer;
  }
  
  .change-log-table {
    overflow-x: auto;
  }
  
  .change-log table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .change-log th,
  .change-log td {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .change-log th {
    font-weight: 600;
    color: #2d3748;
    background-color: #f7fafc;
  }
  
  .change-log tbody tr:hover {
    background-color: #f8fafc;
  }
  
  .type-payment { background-color: rgba(66, 153, 225, 0.1); }
  .type-info_update { background-color: rgba(154, 230, 180, 0.1); }
  .type-note_update { background-color: rgba(246, 173, 85, 0.1); }
  .type-document { background-color: rgba(159, 122, 234, 0.1); }
  
  .manager-info {
    display: flex;
    flex-direction: column;
  }
  
  .manager-name {
    font-weight: 500;
    color: #2d3748;
  }
  
  .manager-id {
    font-size: 0.85em;
    color: #718096;
    margin-top: 2px;
  }
  
  .no-changes {
    text-align: center;
    padding: 40px;
    color: #718096;
    font-size: 1.1em;
  }
  
  .change-log-error {
    text-align: center;
    padding: 20px;
    color: #e53e3e;
    background-color: #fff5f5;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .change-log-header {
      flex-direction: column;
      gap: 10px;
    }
  
    .filter-select {
      width: 100%;
    }
  
    .change-log td {
      padding: 8px;
    }
  }