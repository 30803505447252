/* frontend/src/styles/CompanyCard.css */

.company-card {
    border: 1px solid #ddd;
    padding: 16px;
    margin-top: 16px;
    border-radius: 4px;
  }
  
  .file-upload {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
  }
  
  .message {
    margin-top: 8px;
    color: green;
  }
  