/* frontend/src/styles/ClientList.css */

.client-list {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .client-list thead {
    background-color: #f0f0f0;
  }
  
  .client-list th,
  .client-list td {
    padding: 15px;
    text-align: left;
    font-size: 14px;
    color: #333;
  }
  
  .client-list th {
    font-weight: bold;
  }
  
  .client-list tbody tr {
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .client-list tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .client-list tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .client-list td {
    border-bottom: 1px solid #ddd;
  }
  
  .client-list tbody tr:last-child td {
    border-bottom: none;
  }
  