/* Корневой контейнер для изоляции стилей */
.payment-calculator-root {
  all: initial;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  line-height: 1.4;
  color: #1d1d1f;
  background-color: #f5f5f7;
}

.payment-calculator-root * {
  box-sizing: border-box;
}

/* Контейнер */
.payment-calculator-root .pc-container {
  background-color: #f5f5f7;
  padding: 24px;
  width: 100%;
  max-width: none;
}

/* Секция итогов */
.payment-calculator-root .pc-summary {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.payment-calculator-root .pc-summary-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 20px 0;
  color: #1d1d1f;
}

.payment-calculator-root .pc-summary-content {
  display: grid;
  gap: 12px;
}

.payment-calculator-root .pc-summary-row {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 8px 0;
  border-bottom: 1px solid #f5f5f7;
  font-size: 14px;
}

.payment-calculator-root .pc-section-title {
  font-size: 18px;
  font-weight: 600;
  margin: 24px 0 16px;
  color: #1d1d1f;
}

/* Таблица */
.payment-calculator-root .pc-table-wrapper {
  background-color: #ffffff;
  border-radius: 12px;
  overflow-x: auto;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  width: 100%;
  margin: 0 auto;
}

.payment-calculator-root .pc-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  min-width: 1400px;
}

.payment-calculator-root .pc-table th,
.payment-calculator-root .pc-table td {
  padding: 16px;
  border-top: 1px solid #f5f5f7;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.payment-calculator-root .pc-table th {
  background-color: #f5f5f7;
  font-weight: 500;
  color: #86868b;
  text-align: left;
  padding: 16px;
  border: none;
}

/* Определяем ширину для каждой колонки */
.payment-calculator-root .pc-table th:nth-child(1),
.payment-calculator-root .pc-table td:nth-child(1) {
  width: 5%;
}

.payment-calculator-root .pc-table th:nth-child(2),
.payment-calculator-root .pc-table td:nth-child(2) {
  width: 10%;
}

.payment-calculator-root .pc-table th:nth-child(3),
.payment-calculator-root .pc-table td:nth-child(3) {
  width: 10%;
}

.payment-calculator-root .pc-table th:nth-child(4),
.payment-calculator-root .pc-table td:nth-child(4) {
  width: 10%;
}

.payment-calculator-root .pc-table th:nth-child(5),
.payment-calculator-root .pc-table td:nth-child(5) {
  width: 10%;
}

.payment-calculator-root .pc-table th:nth-child(6),
.payment-calculator-root .pc-table td:nth-child(6) {
  width: 10%;
}

.payment-calculator-root .pc-table th:nth-child(7),
.payment-calculator-root .pc-table td:nth-child(7) {
  width: 10%;
}

.payment-calculator-root .pc-table th:nth-child(8),
.payment-calculator-root .pc-table td:nth-child(8) {
  width: 15%;
}

.payment-calculator-root .pc-table th:nth-child(9),
.payment-calculator-root .pc-table td:nth-child(9) {
  width: 20%;
  min-width: 220px;
}

/* Статусы */
.payment-calculator-root .pc-status {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  width: auto;
  min-width: 120px;
}

.payment-calculator-root .pc-status-paid {
  background-color: rgba(52, 199, 89, 0.1);
  color: #34c759;
}

.payment-calculator-root .pc-status-partial {
  background-color: rgba(255, 159, 10, 0.1);
  color: #ff9f0a;
}

.payment-calculator-root .pc-status-unpaid {
  background-color: #f5f5f7;
  color: #86868b;
}

/* Поля ввода */
.payment-calculator-root .pc-input {
  width: 100%;
  min-width: 120px;
  padding: 8px 12px;
  border: 1px solid #d2d2d7;
  border-radius: 8px;
  font-size: 14px;
  background-color: #ffffff;
}

.payment-calculator-root .pc-input:focus {
  outline: none;
  border-color: #0071e3;
}

/* Кнопки в таблице */
.payment-calculator-root .pc-actions {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  min-width: auto;
  padding-right: 16px;
}

.payment-calculator-root .pc-btn-full,
.payment-calculator-root .pc-btn-partial {
  flex: 1;
  min-width: 90px;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  border: none !important;
  transition: opacity 0.2s;
  white-space: nowrap;
}

.payment-calculator-root .pc-btn-full {
  background-color: #000000 !important;
  color: white !important;
}

.payment-calculator-root .pc-btn-partial {
  background-color: #666666 !important;
  color: white !important;
}

.payment-calculator-root .pc-btn-full:hover,
.payment-calculator-root .pc-btn-partial:hover {
  opacity: 0.9;
}

.payment-calculator-root .pc-btn-full:disabled,
.payment-calculator-root .pc-btn-partial:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Модальное окно */
.payment-calculator-root .pc-modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 20px;
}

.payment-calculator-root .pc-modal-content {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  max-width: 480px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
}

.payment-calculator-root .pc-modal-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 24px 0;
  color: #1d1d1f;
}

.payment-calculator-root .pc-modal-row {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  padding: 8px 0;
  border-bottom: 1px solid #f5f5f7;
}

.payment-calculator-root .pc-form-group {
  margin: 24px 0;
}

.payment-calculator-root .pc-form-label {
  display: block;
  font-size: 14px;
  color: #86868b;
  margin-bottom: 8px;
}

.payment-calculator-root .pc-form-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #d2d2d7;
  border-radius: 8px;
  font-size: 14px;
  background-color: #ffffff;
}

.payment-calculator-root .pc-form-input:focus {
  outline: none;
  border-color: #0071e3;
}

.payment-calculator-root .pc-error {
  color: #ff3b30;
  font-size: 12px;
  margin-top: 4px;
}

/* Кнопки модального окна */
.payment-calculator-root .pc-modal-actions {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}

.payment-calculator-root .pc-modal-btn {
  flex: 1;
  padding: 12px;
  border: none !important;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

.payment-calculator-root .pc-modal-btn-primary {
  background-color: #000000 !important;
  color: white !important;
}

.payment-calculator-root .pc-modal-btn-secondary {
  background-color: #666666 !important;
  color: white !important;
}

.payment-calculator-root .pc-modal-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Адаптивность */
@media (max-width: 1400px) {
  .payment-calculator-root .pc-table {
    min-width: 1400px;
  }
  
  .payment-calculator-root .pc-table-wrapper {
    overflow-x: auto;
  }
}

@media (max-width: 768px) {
  .payment-calculator-root .pc-container {
    padding: 16px;
  }
  
  .payment-calculator-root .pc-summary,
  .payment-calculator-root .pc-modal-content {
    padding: 16px;
  }
  
  .payment-calculator-root .pc-table th,
  .payment-calculator-root .pc-table td {
    padding: 12px;
  }
  
  .payment-calculator-root .pc-modal-btn {
    padding: 10px;
  }
}