/* frontend/src/styles/ManagerList.css */
.manager-list {
    padding: 20px;
  }
  
  .manager-list h1 {
    margin-bottom: 20px;
  }
  
  .manager-list table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .manager-list th, .manager-list td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .manager-list th {
    background-color: #f4f4f4;
  }
  
  .manager-list button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .manager-list button:hover {
    background-color: #c0392b;
  }
  
  .error {
    color: red;
    margin-bottom: 20px;
  }
  